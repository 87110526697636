import React, {useEffect} from "react";
import "./Preloader.css";
import Om from "./om.svg";
import {useLocation} from "react-router-dom";

function Preloader() {

  let location = useLocation();
  let showLoader = "default" === location.key;

  const load = () => {
    let preload = document.querySelector(".preloader");
    if (preload) {
      setTimeout(() => {
        preload.style.opacity = "0";
        setTimeout(() => {
          preload.style.display = "none";
        }, 1000);
      }, 1500);
    }
  }

  useEffect(() => {
    load();
  });

  if (showLoader) {
    return (
      <div className="preloader">
        <div className="spinner_wrap">
          <img src={Om} alt="Om"/>
        </div>
      </div>
    );
  }
}

export default Preloader;
