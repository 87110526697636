import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import "./Topbar.css"
import Om from "../../assets/om.svg";
import {Link} from "react-router-dom";

function Topbar() {
  return (
    <div>
      <Navbar bg="light" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand as={Link} to="/" className="fw-bold m-md-2"><img
            alt="Om"
            src={Om}
            className="d-inline-block align-top "
          />{' '} Hindu Temple Helsingborg</Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
}

export default Topbar;