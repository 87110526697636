import Carousel from 'react-bootstrap/Carousel';


function HomeCarousel() {
  return (
    <Carousel controls={false} indicators={false} className="m-md-5 mt-md-3">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/slider/slider-1.jpeg"
          alt="Om"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/slider/slider-2.jpg"
          alt="Om"
        />
      </Carousel.Item>

    </Carousel>
  );
}

export default HomeCarousel;