function TempleMap() {
  // eslint-disable-next-line no-undef
  const mapSrc = "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJA65Oll4zUkYRVOEqya3v9Vs&zoom=15&key=" + process.env.REACT_APP_GMAP_API_KEY;

  return (
    <iframe
      src={mapSrc}
      width='90%'
      height="300"
      style={{border: 0}}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"></iframe>
  );
}

export default TempleMap;