import Nav from "react-bootstrap/Nav";

function Links() {
  return (
    <Nav className="navbar-nav">
      <Nav.Link href="#">Gallery</Nav.Link>
      <Nav.Link href="#">Events</Nav.Link>
      <Nav.Link href="#">Member Registration</Nav.Link>
      <Nav.Link href="#">Voluntary Contribution</Nav.Link>
      <Nav.Link href="#">Become a Volunteer</Nav.Link>
      <Nav.Link href="#">Weekend Collaboration</Nav.Link>
      <Nav.Link href="#">Temple Prayer Seva</Nav.Link>
    </Nav>
  )
}

export default Links