import Topbar from "./Topbar";
import Preloader from "../Preloader/Preloader";

function Header() {
  return (
    <>
      <Topbar/>

      <Preloader/>
    </>
  )
}

export default Header