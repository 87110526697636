import "./PoojaServices.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {NavLink} from "react-bootstrap";

function PoojaServices() {
  return (
    <div className="PoojaServices">
      <Header/>
      <hr className="clearfix w-100 d-md-none pb-0"/>
      <div className="container m-sm-3">
        <p><b>Namaskar Devotees!</b></p>
        <br/>
        <p>You can now prebook your time in advance for the below poojas/prayers.</p>
        <br/>
        <>
          <li>Dev Darshan / Day pooja (Pooja for any specific day of the week) – 101 SEK</li>
          <li>Vahan Puja (Prayer for new car / automobile) – 251 SEK</li>
          <li>Deep Yagya – pooja available till Sept 1st 2022 only – at Temple 551 SEK, at your address – 2001 SEK</li>
          <li>Janmdiwas Puja (Birthday Prayer) – 251 SEK</li>
          <li>Vivah Varshgaanth Puja (Wedding Anniversary Prayer) – 251 SEK</li>
          <li>Punya Tithi Puja (Death Anniversary Puja) – 2500 SEK</li>
          <li>Grah Pravesh Puja (Prayer for New Home) – Contact Temple Team</li>
          <li>New born baby temple visit and blessings – 251 SEK</li>
        </>
        <br/>
        <p><b>All your contributions will support our collective dream of building the Temple of future.</b></p>
        <br/>
        <p>All pooja bookings need to be done atleast 2 days in advance.</p>
        <br/>
        <u><NavLink href="https://forms.gle/6UpYvSNGBKt9FZna9"
                    target="_blank" rel="noreferrer">For details and date / time booking contact temple team via Pooja
          Service booking form.</NavLink></u>
      </div>
      <Footer/>
    </div>
  );
}

export default PoojaServices;
