import './Footer.css'

function Footer() {
  return (
    <footer className="page-footer font-small">
      <div className="footer-copyright text-center mt-mt-5 py-3">© 2022 Hindu Temple Helsingborg</div>
    </footer>
  )
}

export default Footer