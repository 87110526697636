import "./App.css";
import Header from "../../components/Header/Header";
import HomeCarousel from "../../components/Carousel/HomeCarousel";
import Footer from "../../components/Footer/Footer";
import Body from "../../components/Content/Body";

function App() {
  return (
    <div className="App">
      <Header/>
      <HomeCarousel/>
      <hr className="clearfix w-100 d-md-none pb-0"/>
      <Body />
      <hr className="clearfix w-100 d-md-none pb-0"/>
      <Footer/>
    </div>
  );
}

export default App;
