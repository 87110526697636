import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";

function PoojaServicesLinks() {
  return (
    <Nav className="navbar-nav">
      <Nav.Link as={Link} to="/pooja-services">Wedding Anniversary Pooja</Nav.Link>
      <Nav.Link as={Link} to="/pooja-services">Grihapravesh</Nav.Link>
      <Nav.Link as={Link} to="/pooja-services">Birthday Celebration</Nav.Link>
      <Nav.Link as={Link} to="/pooja-services">Patti Poojan</Nav.Link>
      <Nav.Link as={Link} to="/pooja-services">Annaprashan</Nav.Link>
      <Nav.Link as={Link} to="/pooja-services">Thread Ceremony</Nav.Link>
      <Nav.Link as={Link} to="/pooja-services">Pay a Tribute</Nav.Link>
      <Nav.Link as={Link} to="/pooja-services">Shraddha</Nav.Link>
      <Nav.Link as={Link} to="/pooja-services">Haldi Kumkum</Nav.Link>
      <Nav.Link as={Link} to="/pooja-services">Achievement Blessings</Nav.Link>
    </Nav>

  )
}

export default PoojaServicesLinks