import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/Home/App";
import reportWebVitals from "./reportWebVitals";
import SiteCookieConsent from "./components/Misc/SiteCookieConsent";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import PoojaServices from "./pages/PoojaServices/PoojaServices";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <App/>
  },

  {
    path: "pooja-services",
    element: <PoojaServices/>
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <RouterProvider router={router}/>

    <SiteCookieConsent/>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
