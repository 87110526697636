import {CookieConsent} from "react-cookie-consent";

function SiteCookieConsent() {
  return (
    <CookieConsent
      acceptOnScroll={true}
      acceptOnScrollPercentage={20}
      contentStyle={{color: 'var(--theme-text-color)'}}
      style={{
        background: "#FFFFFF",
        textAlign: "center"
      }}
      buttonStyle={{color: "#FFFFFF", background: "var(--theme-text-color)"}}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>

  )
}

export default SiteCookieConsent