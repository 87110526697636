import './Body.css'
import PoojaServicesLinks from "./PoojaServicesLinks";
import Nav from "react-bootstrap/Nav";
import Links from "./Links";
import TempleMap from "../Misc/TempleLocation";

function Body() {
  return (
    <div className="container-fluid text-center text-md-left mt-md-5">
      <div className="row">

        <div className="col-md-4 mt-md-0 mt-3 mb-3">
          <h5 className="text-uppercase fw-bold">Visiting Temple</h5>
          <b>Temple Timings</b>
          <p>5:00 PM to 7:00 PM CET</p>
          <br/>
          <b>Booking Information</b>
          <p>Visits are <b>strictly</b> by advance booking</p>
          <u><Nav.Link
            href="https://forms.gle/sZzYUuuMPPLuZ6AZ8" target="_blank">Click here to book a slot</Nav.Link></u>
        </div>

        <hr className="clearfix w-100 d-md-none pb-0"/>
        <div className="col-md-4 mt-md-0 mt-3 mb-3">
          <h5 className="text-uppercase fw-bold">Upcoming Events</h5>
          <b>Navratri</b> <p>Temple will be open from 12PM to 6PM from 26th September to 5th October</p>
        </div>

        <hr className="clearfix w-100 d-md-none pb-0"/>
        <div className="col-md-4 mt-md-0 mt-3 mb-3">
          <h5 className="text-uppercase fw-bold">Pooja Services</h5>
          <PoojaServicesLinks/>
        </div>

      </div>

      <hr className="clearfix w-100 d-md-none pb-0"/>
      <div className="row mt-md-5">

        <div className="col-md-4 mt-md-0 mt-3 mb-3">
          <h5 className="text-uppercase fw-bold">Links</h5>
          <Links/>
        </div>

        <hr className="clearfix w-100 d-md-none pb-0"/>
        {/* Location */}
        <div className="col-md-8 mt-md-0 mt-3 mb-3">
          <h5 className="text-uppercase fw-bold">Location</h5>
          <TempleMap/>
        </div>
      </div>
    </div>

  )
}

export default Body